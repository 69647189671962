import React from "react";
import Header from "components/Header/Header.js";
import HeaderLinks, { LeftHeaderLinks } from "components/Header/HeaderLinks.js";
// nodejs library that concatenates classes
import classNames from "classnames";
import Parallax from "components/Parallax/Parallax.js";

// mui components
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import GridContainer from "components/Grid/GridContainer";
import Footer from "components/Footer/Footer";

const useStyles = makeStyles(styles);
const dashboardRoutes = [];

export default ({ ...rest }) => {
  const classes = useStyles();

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="The Twice Over"
        leftLinks={<LeftHeaderLinks />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />

      <Parallax
        small
        filter
        image={require("assets/img/old-movie-projector.png")}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div></div>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.title}>Method</h1>
              <p>
                The enjoyment of film is a subjective matter, and through a static scoring system, 
                there is no way to determine if a film is for you. At The Twice Over, we quantify 
                elements of a film to distinguish its strengths and weaknesses.{" "}
              </p>
              <p>
                This opens the possibility for us to better understand the different elements
                of a film and discuss within our tastes why we have differing opinions on the 
                same film. Let us break down those Essential Elements.{" "}
              </p>

              <ul>
                <li>
                  <strong>Narrative </strong> The story/plot, the pace, and the audience connection.
                </li>

                <li>
                  <strong>Writing </strong> Anything that would have been scripted. Whether dialogue,
                  narration, or lyrics.
                </li>

                <li>
                  <strong>Acting </strong> How believable the execution of the writing was. 
                  For documentaries, consider how compelling and persuasive the subjects are. 
                  In animation, the voice acting.
                </li>

                <li>
                  <strong>Themes </strong> Presentation of the underlying messages, motifs, 
                  and/or ideas.
                </li>

                <li>
                  <strong>Aesthetics </strong> How immersive the visual and audio experience was.
                </li>
              </ul>

              <p>
                For each element we consider the direction, and how well it fits with the other 
                elements and the overall film.{" "}
              </p>

              <p>
                We score each of the above Essential Elements on a scale from 0-100. You can order 
                the elements in order of importance, and depending on that order, the overall Tally 
                will change based on a weighted scale that prioritizes the elements with the highest 
                importance.{" "}
              </p>

              <p>
                From the scores given for each element, we take a composite weighted average to give
                the film a Tally. That is displayed on the website. You can change the order of
                importance of each element to your preference, and the Tally will recalculate to reflect
                that change.{" "}
              </p>

              <p>
                We got tired of the arbitrary scores a film might have on other websites,
                so we created this system to help give meaning to the final Tally. 
                What this provides is insight on what our tastes are, and in practical terms, 
                it will help in choosing what film to watch based on personal taste or mood.{" "}
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
};
