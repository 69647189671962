import { cardTitle, title } from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const teamStyle = theme => ({
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d",
  },
  cardBody: {
    padding: "0px",
  },
  description: {
    color: "#999",
  },
  justifyCenter: {
    justifyContent: "center !important",
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#5FA0BE",
  },
  margin5: {
    margin: "5px",
  },
  scoreContainer: {
    width: "50px",
    height: "50px",
    position: "absolute",
    background: "red",
    top: "20px",
    left: "135px",
    display: "inline-block",
  },
  score: {
    fontSize: "21px",
    textAlign: "center",
    color: "#fff",
    fontWeight: "bold",
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  badge: {
    fontSize: '21px',
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '10px',
    borderRadius: '50%',
    height: '60px',
    width: '60px',
    color: 'white',
    backgroundColor: props => props.backgroundColor
  },
});

export default teamStyle;
