class Reviews {
  static getReviews(update_reviews) {
    fetch("https://api.thetwiceover.com/v1/reviews.json")
      .then((res) => res.json())
      .then((data) => {
        update_reviews(data["reviews"]);
      })
      .catch(console.log);
  }
}

export default Reviews;
