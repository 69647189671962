class Podcast {
  static getPodcasts(update_podcasts) {
    fetch("https://api.thetwiceover.com/v1/movies.json")
      .then((res) => res.json())
      .then((data) => {
        update_podcasts(data["movies"]);
      })
      .catch(console.log);
  }
}

export default Podcast;
