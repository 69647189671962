import React from "react";
import Header from "components/Header/Header.js";
import HeaderLinks, { LeftHeaderLinks } from "components/Header/HeaderLinks.js";
// nodejs library that concatenates classes
import classNames from "classnames";
import Parallax from "components/Parallax/Parallax.js";

// mui components
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import GridContainer from "components/Grid/GridContainer";
import Footer from "components/Footer/Footer";

const useStyles = makeStyles(styles);
const dashboardRoutes = [];

export default ({ ...rest }) => {
  const classes = useStyles();

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="The Twice Over"
        leftLinks={<LeftHeaderLinks />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />

      <Parallax
        small
        filter
        image={require("assets/img/old-movie-projector.png")}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div></div>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.title}>About</h1>
              <p>
                Thanks for visiting The Twice Over website! Here at The Twice Over, 
                we want to be the place that every moviegoer checks out when researching
                a film.{" "}
              </p>

              <p>
                Currently, we spend most of our time working on our Podcast, where we preview
                films and provide in-depth discussions from a variety of perspectives. You can 
                find out Podcast on all the major platforms, including Apple Podcasts, 
                Google Podcasts, Spotify, Pocket Casts, and Castbox.{" "}
              </p>

              <p>
                As a supplement to the Podcast, this website presents the TTO team’s movie ratings 
                based on our modular scoring system; that way our Tally can be customized to account 
                for your personal taste and mood. In the future we intend to increase engagement and 
                create a serious movie enthusiast community on this website as we continue to grow, 
                and eventually expand our reach into all types of entertainment media.{" "}
              </p>

              <h2>The Hosts</h2>

              <p>
                We watch a lot of movies…like a lot. Not necessarily every movie, or even the biggest 
                blockbusters, but between us four, we cover a lot of ground.{" "}
              </p>

              <p>
                We noticed that we were hesitant to suggest some of our favorite films because we were not
                sure if the other person would feel the same way as us. That is because we all have our own 
                preferences when it comes to enjoying a film. Even more so, our mood can change how we feel 
                about a film any given day.{" "}
              </p>

              <p>
                This is why we have our <a href="/method">method</a> for scoring
                a film. What better way to understand your hosts than to see
                how we choose to rank our Essential Elements.
              </p>

              <p>
                <strong>Faraz</strong>
              </p>

              <ol>
                <li>Writing</li>
                <li>Acting</li>
                <li>Aesthetics</li>
                <li>Narrative</li>
                <li>Themes</li>
              </ol>

              <p>
                When I’m watching a movie, I try to keep in mind that everything
                I’m seeing on screen is deliberate. This helps me gauge
                appreciation or displeasure for what I’m watching. At the risk
                of sounding highbrow, it’s when you are able to tell when a
                decision was made with maximizing profits in mind, or when a
                decision was made to present and tell a meaningful story. You
                will notice that movies that I hold in high regard are usually
                movies that impress me most with their art. Meaning, it’s more
                about what the writers and the director wanted me to get out of
                the movie and if they succeeded in doing so, than it is about
                whether I thought it was entertaining to my taste. This is
                obviously a bit generalized, as there are multiple exceptions,
                but I can say this is how I approach most movies.{" "}
              </p>

              <p>
                <strong>Fahad</strong>
              </p>

              <ol>
                <li>Writing</li>
                <li>Themes</li>
                <li>Aesthetics</li>
                <li>Acting</li>
                <li>Narrative</li>
              </ol>

              <p>
                Why do I watch movies? I love a good story. I love reading
                novels, but I find that film is a better medium to tell a story.
                A movie is able to pull you into the lives of the characters
                within such a short amount of time; a few well-constructed
                scenes and we can understand everything we need to about the
                characters and the plot. When I go to the movies I want to see
                something original, something unique, something that can excite.
                At the box office, the most successful movies provide escapist
                action oriented entertainment. Time to time, I enjoy those
                movies. But I want more "meat on the bones". I want a story with
                more lasting power, something meaningful that will keep me
                thinking for days.{" "}
              </p>

              <p>
                When I get asked what made a movie great for me - I struggle
                sometimes to distill it into its elements. Because I think that
                when a film is great, it is far greater than the sum of its
                parts. And when it's bad, all you have are the parts.{" "}
              </p>

              <p>
                <strong>Yousuf</strong>
              </p>

              <ol>
                <li>Narrative</li>
                <li>Acting</li>
                <li>Themes</li>
                <li>Writing</li>
                <li>Aesthetics</li>
              </ol>

              <p>
                As I walk into a movie theater, I have to keep my objectives
                straight. First and foremost, before I do anything else: Theater
                Popcorn. It’s the primary reason I’m there! Otherwise, I’d much
                rather be at home, where I can rewind the movie, turn on
                subtitles, and not deal with a bunch of teens dabbing their way
                through the aisles. Regardless of where I watch though, there’s
                a few more things on my checklist:{" "}
              </p>

              <p>
                (1) A legitimate story. In the end, film is storytelling. A
                well-told one doesn’t have to be very complicated (although
                please, by all means!), but it usually hinges on the journey of
                at least one character. A great film always impresses with its
                ability to convey the magnitude of a character’s development
                within the small space of the big screen.{" "}
              </p>

              <p>
                (2) A legitimate message. Themes, motifs, and symbols tie
                together disconnected elements of the film in ways that the plot
                cannot. Consciously or not, we rely on them heavily for our
                personal connection to the film, which is what we’re all after
                on some level. And frankly, the more you can convey without
                having a character tell me to my face, the better. I’d rather be
                shown than told so I can pretend I was very clever to have
                noticed it. If this all sounds highbrow, I will say again that
                these concepts don’t have to be highly abstract. Consider the
                sheer number of times Vin Diesel harps on the importance of
                family across eight Fast & Furious movies – and he’s still not
                done. Yes, that qualifies as a theme. Even action movies have
                them. In fact, it’s pretty impossible to make a good movie
                without some sort of message (Counterpoint: Snakes on a Plane).{" "}
              </p>

              <p>
                (3) I’m not gonna lie, I’m a sucker for a good twist. I get it,
                not every twist is a work of art (it’s often a cheap thrill
                and/or a band-aid on an otherwise weak story). But the best ones
                present a unique brand of deception which feels organic and
                precipitated by numerous subtle clues. No matter that I could
                not see it coming (“although I kinda did!”, he assured himself
                smugly as he headed for the exits) – all the better if I can
                re-watch for the new experience of catching everything I missed.{" "}
              </p>

              <p>
                <strong>Faaraan</strong>
              </p>

              <ol>
                <li>Acting</li>
                <li>Narrative</li>
                <li>Aesthetics</li>
                <li>Writing</li>
                <li>Themes</li>
              </ol>

              <p>
                I watch movies in an effort to appreciate how people use film as
                an expression of art. I like to see believable dialogue,
                captivating performances, and stories that are told beautifully
                with this combination. I appreciate the creative choices that
                people make in terms of filming perspective that are usually
                lost on viewers. There’s a lot that happens behind the scenes in
                making a successful film, and if everyone does their job
                passionately, I should have a hard time finding the process
                while viewing the film. I shouldn’t be able to see actors
                waiting for their lines, nor should I be able to see rushed
                story lines and forced, dramatic performances from actors as a
                result of lazy directing, acting, writing, or a combination
                thereof. When writers, directors, producers, and actors do
                justice to their role, it shows, and I try to highlight their
                brilliance as best I can.{" "}
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </div>            <Footer />

    </div>
  );
};
