import { get } from "lodash";
import React, { useState, useEffect, useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GraphicEqIcon from '@material-ui/icons/GraphicEq';

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks, { LeftHeaderLinks } from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import TeamSection from "./Sections/TeamSection.js";
import Podcast from "models/Podcast.jsx";
import { Link } from "react-router-dom";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const getFactorsInitial = () => {
  if (localStorage.getItem('factors')) {
    return localStorage.getItem('factors').split(',')
  }

  return [1, 2, 3, 4, 5]
}

export default function LandingPage(props) {
  const classes = useStyles();
  const [movies, setMovies] = useState([]);
  const [factors, updateFactorsState] = useState(getFactorsInitial());
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);


  const handleClick = (event) => {
    console.log('button clicked')
    setAnchorEl(buttonRef.current)
    console.log(buttonRef)
    //setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateFactors = (newFactors) => {
    localStorage.setItem('factors', newFactors);
    updateFactorsState(newFactors)
  }


  const { ...rest } = props;

  useEffect(() => {
    async function fetchData() {
      // You can await here
      await Podcast.getPodcasts(setMovies);

      // ...
    }
    fetchData();
  }, []);

  const latest = get(movies, ["0"], {});

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="The Twice Over"
        leftLinks={<LeftHeaderLinks updateFactors={updateFactors} factors={factors} buttonRef={buttonRef} anchorEl={anchorEl} handleClick={handleClick} handleClose={handleClose} />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/old-movie-projector.png")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Alchemy for Movie Ratings</h1>
              <h4>
                We all have our own unique formula when it comes to concocting the
                perfect film. Be the alchemist and build personalized movie ratings
                by ordering the Essential Elements based on your personal taste and
                see what your Tally is. For more details on the Essential Elements,
                check out our{" "}
                <Link style={{ color: "#5FA0BE" }} to="/method">
                  method
                </Link>{" "}
                page.
              </h4>
              <br />
              <Button
                //color="danger"
                style={{ backgroundColor: "#C38C60", color: "black" }}
                size="lg"
                //href={get(latest, ["podcasts", "0", "path"], "")}
                //target="_blank"
                onClick={handleClick}
                rel="noopener noreferrer"
              >
                <GraphicEqIcon />
                Personalize Now
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <TeamSection factors={factors} movies={movies} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
