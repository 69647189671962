/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import Popover from '@material-ui/core/Popover';
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import Factors from "./Factors";

const useStyles = makeStyles(styles);

export const LeftHeaderLinks = (props) => {
  const classes = useStyles();
  const id = open ? 'simple-popover' : undefined;
  const open = Boolean(props.anchorEl);


  return (
    // commenting out till we have a separate page with all eps
    // <List className={classes.list}>
    //   <ListItem className={classes.listItem}>
    //     <Button
    //       href="https://www.creative-tim.com/product/material-kit-react?ref=mkr-navbar"
    //       color="transparent"
    //       target="_blank"
    //       className={classes.navLink}
    //     >
    //       Episodes
    // </Button>
    //   </ListItem>
    // </List>
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          ref={props.buttonRef}
          color="transparent"
          onClick={props.handleClick}
          target="_blank"
          className={classes.navLink}
        >
          <GraphicEqIcon style={{ color: '#C38C60' }} />
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={props.anchorEl}
          onClose={props.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Factors updateFactors={props.updateFactors} factors={props.factors} />
        </Popover>
      </ListItem >
    </List>
  )
}

export default function HeaderLinks(props) {
  const classes = useStyles();

  const open = Boolean(props.anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <List className={classes.list}>
      {/* <ListItem className={classes.listItem}>
        <Button
          ref={props.buttonRef}
          color="transparent"
          onClick={props.handleClick}
          target="_blank"
          className={classes.navLink}
        >
          <GraphicEqIcon style={{ color: '#C38C60' }} />
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={props.anchorEl}
          onClose={props.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Factors updateFactors={props.updateFactors} factors={props.factors} />
        </Popover>
      </ListItem > */}
      <ListItem className={classes.listItem}>
        <Button
          component={Link} to={'/method'}
          color="transparent"
          className={classes.navLink}
        >
          Method
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          component={Link} to={'/about'}
          className={classes.navLink}
        >
          About
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          component={Link} to={'/data'}
          className={classes.navLink}
        >
          Data
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-twitter"
          title="Follow us on twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/thetwiceover"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/thetwiceover"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/thetwiceover/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
