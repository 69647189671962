import React, { useState, useEffect } from "react";
import { DataGrid } from '@material-ui/data-grid';

import Header from "components/Header/Header.js";
import HeaderLinks, { LeftHeaderLinks } from "components/Header/HeaderLinks.js";
// nodejs library that concatenates classes
import classNames from "classnames";
import Parallax from "components/Parallax/Parallax.js";

// mui components
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import GridContainer from "components/Grid/GridContainer";
import Reviews from "models/Reviews";
import Footer from "components/Footer/Footer";

const useStyles = makeStyles(styles);
const dashboardRoutes = [];

const ItemsMap = {
  factor_1: 'narrative',
  factor_2: 'writing',
  factor_3: 'acting',
  factor_4: 'themes',
  factor_5: 'aesthetics'
}

const columns = [
  {
    headerName: 'Title',
    field: 'title',
    valueGetter: ({ row }) => row.movie.title,
    flex: .5
  },
  {
    headerName: 'Year',
    field: 'year',
    valueGetter: ({ row }) => row.movie.year,
    width: 90
  },
  {
    headerName: 'Host',
    field: 'host',
    valueGetter: ({ row }) => row.user.username,
    width: 140
  },
  {
    headerName: 'Narrative',
    field: 'factor_1',
    flex: .3
  },
  {
    headerName: 'Writing',
    field: 'factor_2',
    flex: .3
  },
  {
    headerName: 'Acting',
    field: 'factor_3',
    flex: .3
  },
  {
    headerName: 'Themes',
    field: 'factor_4',
    flex: .3
  },
  {
    headerName: 'Aesthetics',
    field: 'factor_5',
    flex: .3
  },
]

export default ({ ...rest }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      await Reviews.getReviews(setData);

      // ...
    }
    fetchData();
  }, [])
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="The Twice Over"
        leftLinks={<LeftHeaderLinks />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />

      <Parallax
        small
        filter
        image={require("assets/img/old-movie-projector.png")}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div >
          <div className={classes.container}>
            <GridContainer >
              <div style={{ height: '80vh', width: "100%" }}>
                <DataGrid
                  density={"compact"}
                  pagination
                  columns={columns}
                  rows={data.map(d => ({ ...d, id: d.review_id }))}
                  pageSize={100}
                  disableColumnMenu
                />
              </div>

            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />

    </div>
  );
};
