import React, { useEffect, useState } from "react";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { makeStyles } from "@material-ui/core/styles";
import arrayMove from "array-move";
import { List, ListItem } from "@material-ui/core";
import DragIndicator from "@material-ui/icons/DragHandle";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);
const DragHandle = sortableHandle(() => <img src="https://icon-library.net//images/draggable-icon/draggable-icon-25.jpg" width="18" />);
const SortableItem = SortableElement(({ value }) => (
  <ListItem style={{ cursor: 'move', paddingLeft: '9px', zIndex: 9999 }}>
    <DragHandle />

    <div style={{ marginLeft: '20px', fontSize: '18px' }}>{value}</div>
  </ListItem>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <List>
      {items.map((value, index) => (
        <SortableItem key={`item-${value}`} index={index} value={value} />
      ))}
    </List>
  );
});

const ItemsMap = {
  1: 'Narrative',
  2: 'Writing',
  3: 'Acting',
  4: 'Themes',
  5: 'Aesthetics'
}

export default function Factors(props) {
  const classes = useStyles();
  const [items, updateItems] = useState(props.factors.map(f => ItemsMap[f]));
  const [factors, updateFactors] = useState(props.factors)

  useEffect(() => props.updateFactors(factors), [factors])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    updateItems(arrayMove(items, oldIndex, newIndex));
    updateFactors(arrayMove(factors, oldIndex, newIndex));
  };

  return (
    <SortableList
      helperClass={classes.sortable}
      items={items}
      helperClass="sortable-factors"
      onSortEnd={onSortEnd}
    />
  );
}
