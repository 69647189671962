import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleOutlineSharpIcon from "@material-ui/icons/PlayCircleOutlineSharp";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
import { Badge, withStyles } from "@material-ui/core";

const useStyles = makeStyles(styles);

const getScore = (actual, weight) => {
  const weights = [50, 25, 15, 7, 3];
  const final_weights = weight.map((w, k) => {
    return actual["factor_" + w] * (weights[k] / 100);
  });

  return Math.round(final_weights.reduce((a, b) => a + b));
};

const getBackground = (color1, color2, percent) => {
  const generateHex = (r, g, b) => {
    let R = r.toString(16);
    let G = g.toString(16);
    let B = b.toString(16);

    while (R.length < 2) {
      R = `0${R}`;
    }
    while (G.length < 2) {
      G = `0${G}`;
    }
    while (B.length < 2) {
      B = `0${B}`;
    }

    return `#${R}${G}${B}`;
  };

  const mix = (start, end, prcnt) => start + prcnt * (end - start);

  const red1 = parseInt(color1[1] + color1[2], 16);
  const green1 = parseInt(color1[3] + color1[4], 16);
  const blue1 = parseInt(color1[5] + color1[6], 16);

  const red2 = parseInt(color2[1] + color2[2], 16);
  const green2 = parseInt(color2[3] + color2[4], 16);
  const blue2 = parseInt(color2[5] + color2[6], 16);

  const red = Math.round(mix(red1, red2, percent));
  const green = Math.round(mix(green1, green2, percent));
  const blue = Math.round(mix(blue1, blue2, percent));

  return generateHex(red, green, blue);
};


function StyledBadge({ backgroundColor, ...props }) {
  const styleProps = {
    backgroundColor
  };
  const classes = useStyles(styleProps);

  return (
    <Badge {...props} classes={{ badge: classes.badge }} />
  );
}

export default function TeamSection({ movies, factors }) {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    //classes.imgRoundedCircle,
    classes.imgFluid
  );

  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Movies</h2>
      <div>
        <GridContainer>
          {movies.map(
            ({
              podcasts,
              image,
              title,
              year,
              summary,
              movie_id,
              _matchingData,
            }, index) => (
                <GridItem xs={12} sm={12} md={4} key={movie_id}>
                  <Card plain >
                    <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                      <StyledBadge
                        onClick={() => window.open(podcasts.length && podcasts[0].path)} style={{ cursor: 'pointer' }}
                        key={index}
                        badgeContent={getScore(_matchingData["Reviews"], factors)}
                        backgroundColor={getBackground(
                          "#FF0000",
                          "#00FF00",
                          parseInt(
                            getScore(_matchingData["Reviews"], factors)
                          ) / 100
                        )}
                      >
                        <img
                          src={`https://static.thetwiceover.com/${image["path"]}`}
                          alt={`Poster for ${title}`}
                          className={imageClasses}
                          onClick={() => window.open(podcasts.length && podcasts[0].path)} style={{ cursor: 'pointer' }}
                        />
                      </StyledBadge>
                    </GridItem>
                    <h4 className={classes.cardTitle} onClick={() => window.open(podcasts.length && podcasts[0].path)} style={{ cursor: 'pointer' }}>
                      <a style={{ color: 'black' }} href={podcasts.length && podcasts[0].path} target="_blank" >{title}</a>
                      <small className={classes.smallTitle}> ({year})</small>
                      <Button
                        justIcon
                        color="transparent"
                        className={classes.margin5}
                      >
                        <PlayCircleOutlineSharpIcon
                          className={classes.socials}
                          onClick={() => window.open(podcasts.length && podcasts[0].path)}
                        />
                      </Button>
                    </h4>
                    <CardBody className={classes.cardBody}>
                      <p className={classes.description}>{summary}</p>
                    </CardBody>
                  </Card>
                </GridItem>
              )
          )}
        </GridContainer>
      </div>
    </div>
  );
}
